import * as THREE from 'three';

// import Stats from 'three/examples/jsm/libs/stats.module.js';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

// import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { SSRPass } from 'three/examples/jsm/postprocessing/SSRPass.js';
// import { Reflector } from 'three/examples/jsm/objects/ReflectorForSSRPass.js';
import { ReflectorForSSRPass as Reflector } from './ReflectorForSSRPass.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';
import { liquifyFragmentShader, liquifyVertexShader } from './liquify-shaders';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { ShaderMaterial } from 'three';

const params = {
  enableSSR: true,
  autoRotate: false,
};
let composer;
let ssrPass;
// let gui;

let stats;
let controls;
let camera, scene, renderer;

let groundReflector;
const selects = [];
let model, curvedBg;
const textureLoader = new THREE.TextureLoader();

let container = document.querySelector('.webgl');

const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
const mouse = { x: pos.x, y: pos.y };
const speed = 0.1;
const fpms = 60 / 1000;
let loadPercent = 0;

let liquidPass;

var mouseLiquid = new THREE.Vector2();

const initThreeV3 = function(){
  init();
  animate();

  window.addEventListener("mousemove", e => {
    mouse.x = e.x;
    mouse.y = e.y;
  });

  const mouseCursor = document.querySelector("#custom-cursor");
  const xSet = gsap.quickSetter(mouseCursor, "x", "px");
  const ySet = gsap.quickSetter(mouseCursor, "y", "px");

  gsap.ticker.add((_time, deltaTime) => {

    var delta = deltaTime * fpms;
    var dt = 1.0 - Math.pow(1.0 - speed, delta);

    pos.x += (mouse.x - pos.x) * dt;
    pos.y += (mouse.y - pos.y) * dt;

    xSet(pos.x);
    ySet(pos.y);

    let normalizedX = -1 + 2 * pos.x / window.innerWidth; // [-1, 1]
    let normalizedY = - (-1 + 2 * pos.y / window.innerHeight); // [-1, 1]

    // mouseLiquid.x = normalizedX / 1.75;
    // mouseLiquid.y = normalizedY / 1.75;

    // For blackhole shader
    mouseLiquid.x = pos.x;
    mouseLiquid.y = pos.y;

    gsap.to(camera.position, {duration: 2, z: 0.03 + -normalizedX * 0.25, y: 0.067 + normalizedY * 0.015, ease: "linear"});
  });
}

function init() {
  let width = window.innerWidth;
  let height = window.innerHeight;

  const transitionOverlay = document.querySelector('#transition-overlay');
  gsap.set(transitionOverlay, { backgroundColor: "#6316B6" });
  // gsap.set(".main-menu", { opacity: 0 });

  const loadCounter = document.createElement('span');
  loadCounter.classList.add('load-counter');
  loadCounter.innerHTML = "0<span class='percent-text'>%</span>";

  transitionOverlay.appendChild(loadCounter);

  const loadImg = document.createElement('div');
  loadImg.classList.add('load-img');
  loadImg.innerHTML=`<svg fill="#6316B6" height="400.344" viewBox="0 0 257.771 400.344" width="257.771" xmlns="http://www.w3.org/2000/svg"><path d="m256.646 146.374c-18.256-39.984-44.53-65.078-73.757-65.1-55.257-.05-100.133 89.5-100.234 200.009s44.611 200.137 99.867 200.187c29.227.027 55.547-25.018 73.876-64.969l83.661 65.113.367-400.2zm-74.107 315.974c-20.783-.019-38.153-58.245-42.753-136.606-.793-13.5-1.21-27.593-1.2-42.093.015-16.257.569-32 1.587-46.97 5.162-75.876 22.341-131.666 42.69-131.648 14.407.013 27.174 28 35.185 71.267 5.537 29.9 8.8 67.108 8.766 107.432-.036 38.514-3.077 74.178-8.222 103.349-8.033 45.563-21.199 75.283-36.053 75.269z" class="loader-path" stroke="#f47b47" stroke-width="2" transform="translate(-82.655 -81.27)"/></svg>`;
  transitionOverlay.appendChild(loadImg);

  camera = new THREE.PerspectiveCamera( 35, window.innerWidth / window.innerHeight, 0.1, 15 );
  camera.position.set( -0.46, 0.067, 0.25 );
  // -0.417,0.117,-0.231
  // -0.372,0.077,-0.292

  // -0.344,0.117,-0.344
  // -0.363,0.057,0.315
  camera.lookAt( 0.004, 0.128, 0.008 );

  scene = new THREE.Scene();
  scene.background = new THREE.Color( 0x6316B6 );
  scene.fog = new THREE.Fog( 0x6316B6, 1, 4 );

  container = document.querySelector('.webgl');

  // Ground
  const bgTex = textureLoader.load( "/models/v2/BG_Albedo.jpg" );
  const plane = new THREE.Mesh(
    new THREE.PlaneBufferGeometry( 4, 4 ),
    new THREE.MeshBasicMaterial( { color: 0xFFFFFF, specular: 0x101010, map: bgTex } )
  );
  plane.rotation.x = - Math.PI / 2;
  plane.rotation.z = - Math.PI / 2;
  plane.position.y = 0.0365;
  scene.add( plane );

  // Lights
  const spotLight = new THREE.SpotLight();
  spotLight.angle = Math.PI / 16;
  spotLight.penumbra = 0.5;
  spotLight.intensity = 0.75;
  spotLight.decay = 2;
  spotLight.position.set( - 1, 1, 1 );
  scene.add( spotLight );

  const ambientLight = new THREE.AmbientLight( 0xffffff, .35); // soft white light
  scene.add( ambientLight );

  const pointLight2 = new THREE.PointLight( 0xFFFFFF, 0.75, 10, 2);
  pointLight2.position.set( -.4, plane.position.y + .3, .4 );
  scene.add( pointLight2 );

  const pointLight3 = new THREE.PointLight( 0xFFFFFF, 0.75, 10, 2);
  pointLight3.position.set( -.4, plane.position.y + .3, -.4 );
  scene.add( pointLight3 );

  // const pointLight4 = new THREE.PointLight( 0xFFFFFF, 0.75, 10, 2);
  // pointLight4.position.set( -.2, plane.position.y + .01, 0 );
  // scene.add( pointLight4 );

  const manager = new THREE.LoadingManager();
  manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
    console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
    loadPercent = 0;
  };

  manager.onLoad = function ( ) {
    console.log( 'Loading complete!');
    gsap.to("#transition-overlay", { opacity: 0, duration: 0.6, delay: 1.5 });
    // gsap.to(".main-menu", { opacity: 1, duration: 0.8, delay: 1 });
  };

  manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
    console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );

    let incCounter = setInterval(() => {
      loadCounter.innerHTML = (loadPercent++ + "<span class='percent-text'>%</span>");
      if(loadPercent > 100 * (itemsLoaded / itemsTotal)) {
        clearInterval(incCounter);
      }
    }, 3);

  };


  const loader = new OBJLoader(manager);

  loader.load( '/models/v2/BG_v3.obj', function ( object ) {
    // const bgTex = textureLoader.load( "/models/v2/BG_Albedo.jpg" );
    object.traverse( function ( child ) {
      if ( child.isMesh ) {
        child.material = new THREE.MeshBasicMaterial( {
          map: bgTex
        } );
      }
    } );

    curvedBg = object;
    curvedBg.position.set( .30, 0.5, -.015);
    curvedBg.rotation.set(THREE.MathUtils.degToRad(0),THREE.MathUtils.degToRad(-90),THREE.MathUtils.degToRad(0));
    curvedBg.scale.set(.005, .005, .005);
    scene.add(curvedBg);
  });

  loader.load( '/models/v2/LogoV9.obj', function ( object ) {
    const aTex = textureLoader.load( "/models/v2/A_Albedo_V21.jpg" );
    object.traverse( function ( child ) {
      if ( child.isMesh ) {
        if (child.name === "Circle001"){
          child.material = new THREE.MeshLambertMaterial( {
            map: aTex,
          } );
        }
      }
    } );

    model = object;
    model.position.set( .20, plane.position.y + .141, .015);
    model.scale.set(.01, .01, .01);
    model.rotation.set(THREE.MathUtils.degToRad(0),THREE.MathUtils.degToRad(-90),THREE.MathUtils.degToRad(0));
    scene.add( model );
    selects.push( model );
  } );

  // const bumpTex = textureLoader.load( "/models/v2/floorDistort_V2.jpg" );
  const planneGeometry = new THREE.PlaneBufferGeometry( 8, 8 );
  groundReflector = new Reflector( planneGeometry, {
    clipBias: 0.003,
    textureWidth: window.innerWidth,
    textureHeight: window.innerHeight,
    color: 0x888888,
    useDepthTexture: true,
  } );
  groundReflector.material.depthWrite = false;
  groundReflector.position.y = plane.position.y + .0001;
  groundReflector.rotation.x = - Math.PI / 2;
  groundReflector.visible = false;

  groundReflector.isFresnel = true;
  groundReflector.isDistanceAttenuation = true;
  groundReflector.maxDistance = .15;
  groundReflector.opacity = .25;

  scene.add( groundReflector );

  // renderer
  renderer = new THREE.WebGLRenderer( { antialias: false } );
  renderer.setSize( window.innerWidth, window.innerHeight );
  // renderer.outputEncoding = THREE.sRGBEncoding;
  container.appendChild( renderer.domElement );

  controls = new OrbitControls( camera, renderer.domElement );
  controls.enableDamping = true;
  controls.target.set( 0.004, 0.128, 0.02 );
  controls.update();
  controls.enabled = ! params.autoRotate;

  // STATS

  // stats = new Stats();
  // container.appendChild( stats.dom );

  window.addEventListener( 'resize', onWindowResize, false );
  // window.addEventListener("mousemove", onCursorLightMove);

  // composer

  composer = new EffectComposer( renderer );
  ssrPass = new SSRPass( {
    renderer,
    scene,
    camera,
    width: window.innerWidth,
    height: window.innerHeight,
    // encoding: THREE.sRGBEncoding,
    isPerspectiveCamera: true,
    groundReflector: groundReflector,
    selects: selects,
    maxDistance: .075,
    opacity: 1,
    output: SSRPass.OUTPUT.Default,
    isBouncing: false,
    surfDist: 0.0015,
    isInfiniteThick: false,
    thickTolerance: 0.03,
    isBlur: true,
  } );

  composer.addPass( ssrPass );

  const saoPass = new SAOPass( scene, camera, false, true );
  saoPass.params.output = SAOPass.OUTPUT.Default;
  saoPass.params.saoBias = 1;
  saoPass.params.saoIntensity = 0.015;
  saoPass.params.saoScale = 0.3;
  saoPass.params.saoKernelRadius = 80;
  saoPass.params.saoMinResolution = 0;
  saoPass.params.saoBlur = true;
  saoPass.params.saoBlurRadius = 8;
  saoPass.params.saoBlurStdDev = 4;
  saoPass.params.saoBlurDepthCutoff = 0.01;

  composer.addPass( saoPass );

  const liquidUniforms = {
    "tDiffuse": { value: null },
    "resolution": { value: new THREE.Vector2(width, height) },
    "uTime": { value: 1.0 },
    "mouse": { value: new THREE.Vector2(-1.0, -1.0) },
    "mass": {value: 0.00025 }
  };

  if (window.innerWidth >= 1024) {
    const shaderMat = new ShaderMaterial({
      uniforms: liquidUniforms,
      vertexShader: liquifyVertexShader(),
      fragmentShader: liquifyFragmentShader()
    });

    liquidPass = new ShaderPass(shaderMat);
    composer.addPass(liquidPass);
  }

  const pixelRatio = renderer.getPixelRatio();
  const smaaPass = new SMAAPass( container.offsetWidth * pixelRatio, container.offsetHeight * pixelRatio );
  composer.addPass( smaaPass );

  // GUI

  // gui = new GUI();

  // const cameraFolder = gui.addFolder("Camera")
  // const cameraPosFolder = cameraFolder.addFolder("Position")
  // cameraPosFolder.add(controls.object.position, "x", -5, 5, 0.001).listen()
  // cameraPosFolder.add(controls.object.position, "y", -5, 5, 0.001).listen()
  // cameraPosFolder.add(controls.object.position, "z", -5, 5, 0.001).listen()
  // cameraPosFolder.open()

  // const cameraLookAtFolder = cameraFolder.addFolder("Look At")
  // cameraLookAtFolder.add(controls.target, "x", -5, 5, 0.001).listen()
  // cameraLookAtFolder.add(controls.target, "y", -5, 5, 0.001).listen()
  // cameraLookAtFolder.add(controls.target, "z", -5, 5, 0.001).listen()
  // cameraLookAtFolder.open()

  // cameraFolder.open()

  // gui.add( params, 'enableSSR' ).name( 'Enable SSR' );
  // gui.add( params, 'isGroundReflector' ).onChange( () => {

  //   if ( params.isGroundReflector ) {

  //     ssrPass.groundReflector = groundReflector,
  //     ssrPass.selects = selects;

  //   } else {

  //     ssrPass.groundReflector = null,
  //     ssrPass.selects = null;

  //   }

  // } );
  // gui.add( params, 'autoRotate' ).onChange( () => {

  //   controls.enabled = ! params.autoRotate;

  // } );

  // const folder = gui.addFolder( 'more settings' );
  // folder.add( ssrPass, 'isFresnel' ).onChange( ()=>{

  //   groundReflector.isFresnel = ssrPass.isFresnel;

  // } );
  // folder.add( ssrPass, 'isDistanceAttenuation' ).onChange( ()=>{

  //   groundReflector.isDistanceAttenuation = ssrPass.isDistanceAttenuation;

  // } );
  // ssrPass.maxDistance = .075;
  // groundReflector.maxDistance = ssrPass.maxDistance;
  // folder.add( ssrPass, 'maxDistance' ).min( 0 ).max( .5 ).step( .001 ).onChange( ()=>{

  //   groundReflector.maxDistance = ssrPass.maxDistance;

  // } );
  // folder.add( params, 'isOtherMeshes' ).onChange( () => {

  //   if ( params.isOtherMeshes ) {

  //     otherMeshes.forEach( mesh => mesh.visible = true );

  //   } else {

  //     otherMeshes.forEach( mesh => mesh.visible = false );

  //   }

  // } );
  // folder.add( ssrPass, 'isBouncing' );
  // folder.add( ssrPass, 'output', {
  //   'Default': SSRPass.OUTPUT.Default,
  //   'SSR Only': SSRPass.OUTPUT.SSR,
  //   'Beauty': SSRPass.OUTPUT.Beauty,
  //   'Depth': SSRPass.OUTPUT.Depth,
  //   'Normal': SSRPass.OUTPUT.Normal,
  //   'Metalness': SSRPass.OUTPUT.Metalness,
  // } ).onChange( function ( value ) {

  //   ssrPass.output = parseInt( value );

  // } );
  // ssrPass.opacity = 1;
  // groundReflector.opacity = ssrPass.opacity;
  // folder.add( ssrPass, 'opacity' ).min( 0 ).max( 1 ).onChange( ()=>{

  //   groundReflector.opacity = ssrPass.opacity;

  // } );

  // if ( isPerspectiveCamera ) {

  //   ssrPass.surfDist = 0.0015;
  //   folder.add( ssrPass, 'surfDist' ).min( 0 ).max( .005 ).step( .0001 );

  // } else {

  //   ssrPass.surfDist = 2;
  //   folder.add( ssrPass, 'surfDist' ).min( 0 ).max( 7 ).step( .01 );

  // }

  // folder.add( ssrPass, 'isInfiniteThick' );
  // folder.add( ssrPass, 'thickTolerance' ).min( 0 ).max( .05 ).step( .0001 );
  // folder.add( ssrPass, 'isBlur' );
  // gui.close()

}


function onWindowResize() {

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );
  composer.setSize( window.innerWidth, window.innerHeight );
  groundReflector.getRenderTarget().setSize( window.innerWidth, window.innerHeight );

}

function animate() {

  requestAnimationFrame( animate );

  // stats.begin();
  render();
  // stats.end();

}

function render() {
  if ( params.autoRotate || window.innerWidth < 1024 ) {

    const timer = Date.now() * 0.0005;

    // camera.position.x = Math.sin( timer ) * 0.5;
    // camera.position.y = 0.1;
    // camera.position.z = Math.cos( timer ) * 0.5;
    camera.position.x = -0.7;
    camera.position.y = 0.067;
    camera.position.z = 0.25 * Math.sin( timer );
    // camera.position.z = 0.25;
    camera.lookAt( 0.004, 0.128, 0.028 );
  } else {

    controls.update();

  }

  if (window.innerWidth >= 1024) {
    liquidPass.uniforms.mouse.value.copy(mouseLiquid);
    liquidPass.uniforms.uTime++;
  }

  composer.render();
}

export { initThreeV3 };