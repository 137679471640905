import Plyr from 'plyr';

export const artistPage = () => {
  $("#biography-anchor").click(function() {
    if(window.innerWidth < 1024){
      scrollToAnchor('mobile-biography-scroll-target');
    } else {
      scrollToAnchor('biography-scroll-target');
    }
  });
  $("#releases-anchor").click(function() {
    scrollToAnchor('releases-scroll-target');
  });
  $("#events-anchor").click(function() {
    scrollToAnchor('events-scroll-target');
  });

  if(document.querySelector("#player")){
    const plyrPlayer = new Plyr('#player');
  }

  let currentPlayer = null;

  $(".control-btn").click(function(){
    const $this = $(this);

    const playerId = $this.data('playerid')
    const thisPlayer = songPlayers[playerId];

    if(currentPlayer && currentPlayer !== thisPlayer && (currentPlayer.playing || currentPlayer.ended)) {
      currentPlayer.pause();
      $(".control-btn.playing").children().removeClass('fa-pause').addClass('fa-play');
      $(".control-btn.playing").removeClass('playing');
    }

    if (thisPlayer.playing) {
      $this.children().removeClass('fa-pause').addClass('fa-play');
      $this.removeClass('playing');
      thisPlayer.pause();
    } else {
      $this.children().removeClass('fa-play').addClass('fa-pause');
      $this.addClass('playing');
      thisPlayer.play();
      currentPlayer = thisPlayer;
    }
  });

  let songPlayers = [];
  $(".song-player").each(function(){
    songPlayers.push(new Plyr(this));

    this.addEventListener('ready', function(evt){
      const instance = evt.detail.plyr;
      instance.on('loadeddata', function(){
        const mins = parseInt(instance.duration / 60);
        const secs = parseInt(instance.duration % 60);
        $(this).closest('.song').find('.song-timer').text(`${('0' + mins).slice(-2)}:${('0' + secs).slice(-2)}`);
      });
      instance.on('timeupdate', function(){
        const mins = parseInt(instance.currentTime / 60);
        const secs = parseInt(instance.currentTime % 60);
        $(this).closest('.song').find('.song-timer').text(`${('0' + mins).slice(-2)}:${('0' + secs).slice(-2)}`);
      });

    });
    // $(this).closest('.song-timer').text(songPlayer.duration);
  })
}

const scrollToAnchor = (aid) => {
  var aTag = $("#"+ aid);
  $('html,body').animate({scrollTop: aTag.offset().top},'slow');
}