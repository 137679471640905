/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')

import { initThreeV3 } from '../init-three-v3';
import { horizontalScrollbar } from '../horizontal-scrollbar';
import { customCursor } from '../custom-cursor';
import { contactPage } from '../contact-page';
import { artistPage } from '../artist-page';

import { setupTransitionAnimations, runTransitionAnimations } from '../transitions';

import * as Turbo from "@hotwired/turbo"


setupTransitionAnimations();

document.addEventListener("turbo:load", function(){
  console.log("turbo load");

  initAkti();

  runTransitionAnimations();

  let hScrollbar;
  const scrollbarEl = document.querySelector("#scrollbar");
  if(scrollbarEl){
    hScrollbar = horizontalScrollbar(scrollbarEl);
  }

  if(document.querySelector('.webgl')){
    initThreeV3();
  } else {
    customCursor(hScrollbar);
  }

  if(document.querySelector(".contact-page")){
    contactPage();
  }

  if(document.querySelector(".artists-show")){
    artistPage();
  }

  if (window.innerWidth < 1024 ) {
    document.querySelector(".burger-icon").addEventListener('click', function(){
      $(this).toggleClass("menu-open");
      $('.mobile-menu-container').toggleClass("menu-open");
      $('body').toggleClass("menu-open");
      $('.site-header-logo img.oposite').toggleClass("d-none");
      $('.site-header-logo img.same').toggleClass("d-none");
    });
  }

});

// document.addEventListener("turbo:before-render", function(){
//   console.log("turbo before render");
// });

document.addEventListener("turbo:before-cache", function(){
  console.log("turbo before cache");
});

document.addEventListener("DOMContentLoaded",function(){
  window.Turbo = Turbo;
});





