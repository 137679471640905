import lozad from 'lozad';

export const wrap = (el, wrapper) => {
  el.parentNode.insertBefore(wrapper, el)
  wrapper.appendChild(el)
}

export const unwrap = (content) => {
  for (let i = 0; i < content.length; i++) {
      const el     = content[i]
      const parent = el.parentNode

      if (parent.parentNode) parent.outerHTML = el.innerHTML
  }
}

export const setupTransitionAnimations = () => {
  let isAnimating = false;
  let targetColor = "purple"

  document.addEventListener("turbo:click", function(event){
    console.log("turbo click: " + event.detail.url);

    targetColor = event.target.dataset.color;
  });

  document.addEventListener("turbo:before-visit", function(e){
    console.log("turbo before visit");
    console.log("turbo before visit url: " + e.detail.url);

    const mainContent = document.querySelectorAll('.main-content, footer');
    gsap.to(mainContent, { opacity: 0, duration: 0.2 });
  });

  document.addEventListener("turbo:before-render", function(e){
    console.log("turbo before render");

    const newBody = e.detail.newBody;
    const transitionOverlay = newBody.querySelector('#transition-overlay');

    if(newBody.classList.contains('home')) return;

    if(newBody.classList.contains('orange')){
      console.log("Setting color orange");
      gsap.set(transitionOverlay, { backgroundColor: "#F47B47" })
      // gsap.to(newBody, { backgroundColor: "#6316B6", duration: 0.3 });
      gsap.set(newBody, { backgroundColor: "#6316B6" });
    } else {
      console.log("Setting color purple");
      gsap.set(transitionOverlay, { backgroundColor: "#6316B6" });
      // gsap.to(newBody, { backgroundColor: "#F47B47", duration: 0.3 });
      gsap.set(newBody, { backgroundColor: "#F47B47" });
    }

    gsap.from(transitionOverlay, { yPercent: -100, duration: 0.4, delay: 0.3, onComplete: () => {
      if(newBody.classList.contains('orange')){
        gsap.set(newBody, { backgroundColor: "#F47B47" });
      } else {
        gsap.set(newBody, { backgroundColor: "#6316B6" });
      }
      gsap.to(transitionOverlay, { opacity: 0, duration: 0.25 });
    }});
  });
};

export const runTransitionAnimations = () => {
  const wrapElements = (els) => {
    els.forEach((t) => {
      const div = document.createElement('div')
      div.classList.add('block-ctn')
      wrap(t, div)
    })
  }

  const texts = document.querySelectorAll('h2,h1,.mail-us li,.demos li, .cta, .link-wrapper, .months-list, .events-row');
  if(texts){
    wrapElements(texts);
    gsap.set(texts, {opacity: 0});
    gsap.set(texts, {opacity: 1, delay: 0.4});
    gsap.from(texts, {
      yPercent: 100,
      force3D: true,
      duration: 1,
      delay: 0.4
    });
  }

  const fromLeft = document.querySelectorAll('img.from-left');
  if(fromLeft){
    wrapElements(fromLeft);
    gsap.set(fromLeft, {opacity: 0});
    gsap.set(fromLeft, {opacity: 1, delay: 0.4});
    gsap.from(fromLeft, {
      xPercent: -101,
      force3D: true,
      duration: 1,
      delay: 0.4
    });
  }

  const fromRight = document.querySelectorAll('img.from-right');
  if(fromRight){
    wrapElements(fromRight);
    gsap.set(fromRight, {opacity: 0});
    gsap.set(fromRight, {opacity: 1, delay: 0.4});
    gsap.from(fromRight, {
      xPercent: 101,
      force3D: true,
      duration: 1,
      delay: 0.45
    });
  }

  const fromBottom = document.querySelectorAll('img.from-bottom');
  if(fromBottom){
    wrapElements(fromBottom);
    gsap.set(fromBottom, {opacity: 0});
    gsap.set(fromBottom, {opacity: 1, delay: 0.1});
    gsap.from(fromBottom, {
      yPercent: 100,
      force3D: true,
      duration: 0.1,
      delay: 0.4
    });
  }

  const observer = lozad('.lozad', {
    rootMargin: '10px 0px',
    threshold: 0.1,
    enableAutoReload: true,
  });
  observer.observe();


  const paragraphs = document.querySelectorAll('p');
  if(paragraphs) {
    gsap.from(paragraphs, {
      yPercent: 50,
      opacity: 0,
      force3D: true,
      duration: 0.8,
      delay: 0.6
    });
  }

  // const events = document.querySelector('.events-wrapper');
  // if(events) {
  //   // gsap.set(events, {opacity: 0});
  //   gsap.from(events, {yPercent: 10, opacity: 0, duration: 0.8, delay: 0.6});
  // }

  gsap.from(".site-header, .scroll-content", { opacity: 0, duration: 0.8, ease: "Power2.In" })

  const artistsPage = document.querySelector('.artists-page');
  if(artistsPage) {
    const artistImages = document.querySelectorAll('.artist-img');
    wrapElements(artistImages);

    const observer = lozad('.lozad-artists', {
      rootMargin: '10px 0px',
      threshold: 0.1,
      enableAutoReload: true,
      loaded: function(el) {
        gsap.from(el, { xPercent: 101, duration: 1, delay: 0.5 });
      }
    });
    observer.observe();
  }


};