import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';

export const horizontalScrollbar = (scrollbar) => {
  console.log("Initializing Horizontal Scrollbar...");

  let myHorizontalScrollbar;

  if(scrollbar){
    class HorizontalScrollPlugin extends ScrollbarPlugin {
      static pluginName = "horizontalScroll";

      transformDelta(delta, fromEvent) {
        if (!/wheel/.test(fromEvent.type)) {
          return delta;
        }

        const { x, y } = delta;

        return {
          y: 0,
          x: Math.abs(x) > Math.abs(y) ? -x : y
        };
      }
    }

    Scrollbar.use(HorizontalScrollPlugin, OverscrollPlugin);
    myHorizontalScrollbar = Scrollbar.init(scrollbar);

    myHorizontalScrollbar.setPosition(0, 0);
    myHorizontalScrollbar.track.xAxis.element.remove();

    return myHorizontalScrollbar;
  }
};