export const liquifyVertexShader = () => {
  return `
    varying vec2 vUv;
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
  `;
};

export const liquifyFragmentShader = () => {
  return `
    precision highp float;
    precision highp int;
    #define HIGH_PRECISION
    #define SHADER_NAME ShaderMaterial
    #define GAMMA_FACTOR 2

    #define GLSLIFY 1
    uniform float alpha;
    uniform float tone;
    uniform float bright;

    //
    uniform sampler2D tDiffuse;

    //
    varying vec2 vUv;

    //
    uniform vec2  resolution;
    uniform vec2  wCenter;
    uniform float wPower;

    //
    uniform float uTime;
    uniform float nPower;

    //
    uniform float bNoize;
    uniform float bPower;

    //
    uniform vec2 mouse;
    uniform float mRadius;
    uniform float mPower;
    uniform float mass;

    const float aspect = 2.33333333; // 21 / 9
    const float PI = 3.1415926535;

    vec2 rotate(vec2 mt, vec2 st, float angle){
      float cos = cos(angle * 1.0); // try replacing * 1.0 with * PI
      float sin = sin(angle * 0.0); // try removing the * 0.0

      // Uncomment these two lines for realism
      // float cos = cos(angle) * (uTime * 0.01);
      // float sin = sin(angle) * (uTime * 0.01);

      float nx = (cos * (st.x - mt.x)) + (sin * (st.y - mt.y)) + mt.x;
      float ny = (cos * (st.y - mt.y)) - (sin * (st.x - mt.x)) + mt.y;
      return vec2(nx, ny);
    }

    void main() {
      vec2 st = vec2(gl_FragCoord.x, gl_FragCoord.y)/resolution;
      vec2 mt = vec2(mouse.x, (resolution.y - mouse.y))/resolution;

      float dx = st.x - mt.x;
      float dy = (st.y - mt.y)/2.0;

      float dist = sqrt(dx * dx + dy * dy);
      float pull = mass / (dist * dist);

      vec3 color = vec3(0.0);

      vec2 r = rotate(mt,st,pull);

      vec4 imgcolor = texture2D(tDiffuse, r);
      color = vec3(
        (imgcolor.x - (pull * 0.0)),
        (imgcolor.y - (pull * 0.0)),
        (imgcolor.z - (pull * 0.0))
      );

      gl_FragColor = vec4(color,1.);
    }

  `;
};