export const contactPage = () => {
  document.querySelector(".cta").addEventListener('click', function(){
    document.querySelector(".demo-form").classList.toggle('visible');
  });

  document.querySelector(".input-area-field").addEventListener('click', function(){
    document.querySelector(".submission-btn-mobile").classList.add('visible');
  });

  document.querySelector("#contact_demo_file").addEventListener('change', function(el){
    var filename = el.currentTarget.value.replace(/^.*[\\\/]/, '');
    var label = document.getElementById('upload-demo-label');
    label.innerText = filename;
    label.classList.add('has-upload');
  });
}