export const customCursor = (hScrollbar) => {
  const storedPosX = localStorage.getItem('mouseX');
  const storedPosY = localStorage.getItem('mouseY');
  const pos = {
    x: storedPosX ? parseInt(storedPosX) : window.innerWidth / 2,
    y: storedPosY ? parseInt(storedPosY) : window.innerHeight / 2
  };
  const mouse = { x: pos.x, y: pos.y };
  const speed = 0.1;
  const fpms = 60 / 1000;

  window.addEventListener("mousemove", e => {
    mouse.x = e.x;
    mouse.y = e.y;
  });

  const mouseCursor = document.querySelector("#custom-cursor");
  const xSet = gsap.quickSetter(mouseCursor, "x", "px");
  const ySet = gsap.quickSetter(mouseCursor, "y", "px");

  let artistNames, xNameSet, yNameSet, namePos, nameSpeed;

  const isArtistsPage = document.querySelector(".artists-page");

  if(isArtistsPage && hScrollbar){
    artistNames = document.querySelectorAll(".artist-name-hover");
    xNameSet = gsap.quickSetter(artistNames, "x", "px");
    yNameSet = gsap.quickSetter(artistNames, "y", "px");
    namePos = {x: pos.x, y: pos.y};
    nameSpeed = 0.05;
  }

  gsap.ticker.add((_time, deltaTime) => {

    var delta = deltaTime * fpms;
    var dt = 1.0 - Math.pow(1.0 - speed, delta);

    pos.x += (mouse.x - pos.x) * dt;
    pos.y += (mouse.y - pos.y) * dt;

    xSet(pos.x);
    ySet(pos.y);

    if(isArtistsPage && hScrollbar){
      var ndt = 1.0 - Math.pow(1.0 - nameSpeed, delta);
      namePos.x += (mouse.x - namePos.x) * ndt;
      namePos.y += (mouse.y - namePos.y) * ndt;

      xNameSet(namePos.x + hScrollbar.scrollLeft);
      yNameSet(namePos.y);
    }
  });
}